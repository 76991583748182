<template>
  <div class="dashboard">
    <v-container fluid>
      <v-row>
        <v-col cols="9">
          <game-filter-bar></game-filter-bar>
          <game-sports-tabs></game-sports-tabs>
        </v-col>
        <v-col cols="3" class="pt-0 px-0 rail">
          <game-build-hand></game-build-hand>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Dashboard.vue',
  data(){
    return{
    }
  },
}
</script>

<style lang="scss">
  .dashboard{
    .rail{
      background: rgba(0,0,0,0.07);
      z-index: 0;
      box-shadow: 0px 20px 10px inset rgba(0,0,0,0.2);
    }
  }
</style>
